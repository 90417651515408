<main id="nav-content">
<nav class="navbar sticky-top navbar-expand-lg navbar-light nav_bar_title "  [ngStyle]="{'color': cms.colorcode.value, 'background-color': cms.colorfrontblack.value,'font-size': cms.fontsize.value +'px'}"  >
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <!-- <button class="navbar-toggler" type="button" data-toggle="modal" data-target="#login-modal"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        Log In
    </button> -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="nav-menu" *ngFor="let menu of menus">            
            <li *ngIf="!menu.submenu.length" [class.active-item]="selectedPath === menu.path" class="nav-item">
                <a *ngIf="cms.lang.value=='English'" class="nav-link" [routerLink]="[menu.path]" routerLinkActive="router-link-active" 
                    onMouseOver="this.style.color='#007bff'" onMouseOut="this.style.color='white'" title="{{menu.title}}">
                <!-- <li  class="{{menu.icon}}"></li>&nbsp; -->
                    <span >{{menu.title}}</span>
                    </a>
                    <a *ngIf="cms.lang.value=='Hindi'" class="nav-link" [routerLink]="[menu.path]" routerLinkActive="router-link-active" 
                    onMouseOver="this.style.color='#007bff'" onMouseOut="this.style.color='white'" title="{{menu.titlehi}}">
                    <span >{{menu.titlehi}}</span> </a>
            </li>

            <li *ngIf="menu.submenu.length" class="nav-item dropdown">
                <a *ngIf="cms.lang.value=='English'" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"  title="{{menu.title}}">
                    <!-- <li  class="{{menu.icon}}"></li>&nbsp; -->
                    <span >{{menu.title}}</span>
                </a>
                    
                <a *ngIf="cms.lang.value=='Hindi'" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" title="{{menu.titlehi}}">    
                    <span >{{menu.titlehi}}</span>
                </a>
                <div class="dropdown-menu sub-nav col-1" aria-labelledby="navbarDropdown" [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value}">
                    <ul class="sub-nav-group">       
                        <li *ngFor="let sub of menu.submenu">
                            <a *ngIf="cms.lang.value=='English'" class="dropdown-item" [routerLink]="[sub.path]" routerLinkActive="router-link-active"  title="{{sub.title}}">
                                <span >{{sub.title}}</span></a>
                            <a *ngIf="cms.lang.value=='Hindi'" class="dropdown-item" [routerLink]="[sub.path]"
                                routerLinkActive="router-link-active" title="{{sub.titlehi}}"><span
                                    >{{sub.titlehi}}</span>
                            </a>    
                        </li>
                    </ul>
                </div>
            </li>           
        </ul>
        <!-- <span style="flex: 1 1 auto;"></span>
        <span style="cursor: pointer;" class="nav-item" data-toggle="modal" data-target="#login-modal">
            <a class="nav-link" *ngIf="cms.lang.value=='English'"><li  class="fa fa-sign-in"></li>&nbsp; Log In</a>
            <a class="nav-link" *ngIf="cms.lang.value=='Hindi'"><li  class="fa fa-sign-in"></li>&nbsp; लॉग इन करें</a>
        </span> -->
    </div>
</nav>
<nav class="main-menu clearfix" id="overflow_menu">
    <ul class="nav-menu clearfix">
    </ul>
</nav>


<!-- Login Modal -->
<div class="modal fade" id="login-modal">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header text-center d-block border-bottom-0">
                <h4 class="modal-title"><i class="fa fa-user" aria-hidden="true"></i>&nbsp; Department Log In</h4>
                <button type="button" #close class="close position-absolute" style="right: 15px; top: 8px;"
                    data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form [formGroup]="loginForm" (ngSubmit)="login()">
                    <div class="form-group">
                        <label for="login-email"><i class="fa fa-user" aria-hidden="true"></i>&nbsp;Username: </label>
                        <input type="text" class="form-control" id="login-email" placeholder="Enter Username"
                            formControlName="username">
                        <div *ngIf="isValidInput('username')">
                            <p class="text-danger" *ngIf="loginForm.controls['username'].errors.required">
                                Username is <strong>required</strong>.
                            </p>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="login-pwd"><i class="fa fa-key" aria-hidden="true"></i>&nbsp;Password:</label>
                        <input type="{{showPassword ? 'text':'password'}}" class="form-control" id="login-pwd"
                            placeholder="Enter password" formControlName="password">
                        <div *ngIf="isValidInput('password')">
                            <p class="text-danger" *ngIf="loginForm.controls['password'].errors.required">
                                Password is <strong>required</strong>.
                            </p>
                        </div>
                    </div>
                    <div class="form-group form-check">
                        <input class="form-check-input" type="checkbox" (change)="showPassword=!showPassword"
                            id="login-rem" name="remember"><i class="fa fa-eye" aria-hidden="true"></i>&nbsp;Show password
                    </div>
                    <div class="form-group row">
                        <div class="col-md-6" style="padding-left: 0px;padding-right: 0px;">
                            <div #dataContainer class="svg-img"></div>
                            <div>
                                <button type="button" (click)="svgcaptcha()">
                                    <mat-icon>replay</mat-icon>
                                </button>

                            </div>
                        </div>
                        <div class="col-md-6" style="padding-left: 0px;padding-right: 0px;">
                            <label for="login-captcha" autocomplete="off">
                            <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;Captcha:</label>
                            <input type="text" id="login-captcha" class="form-control" placeholder="Enter Captcha"
                                formControlName="captcha">
                        </div>
                    </div>
                    <div class="text-danger text-center" *ngIf="showInvalid"><strong> {{errorText}}</strong></div>
                    <p class="text-danger text-center" *ngIf="showCaptchaError"><strong>Wrong Captcha</strong></p>
                    <div class="text-center py-4">
                        <button type="submit" class="btn btn-primary b-btn">Log
                            In</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
</main>
<!-- [disabled]="!loginForm.valid" -->
<app-loader></app-loader>