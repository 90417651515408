import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-viksitdownload',
  templateUrl: './viksitdownload.component.html',
  styleUrls: ['./viksitdownload.component.scss']
})
export class ViksitdownloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
