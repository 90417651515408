import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { createHostListener } from '@angular/compiler/src/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})

export class FullComponent implements OnInit {
  skipLinkPath: string;
  public lastEvent: any;
  rootUrl = environment.rootUrl;
  constructor(public translate: TranslateService, public cms: CommonService, private router: Router, private titleService: Title) 
  {
    translate.addLangs(['English', 'Hindi']);
    translate.setDefaultLang('Hindi');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/|Hindi/) ? browserLang : 'Hindi');    
  }
  
  ngOnInit(): void { 
    this.useLanguage('Hindi');
    this.skipLinkPath = `${this.router.url}#main-content`;
    this.skipLinkPath = `${this.router.url}#nav-content`;
  }

  // useLanguage(language: string) {
  //   this.translate.use(language);
  //   this.langChange(language);
  // }

  langChange(la: any) {
    this.cms.changeLanguage(la);
  }

  increase() {
    this.cms.fontsize.next(16);
    this.cms.fontHeadersize.next(22);
    this.cms.fontSmallsize.next(12);
    
  }

  decrease() {
    this.cms.fontsize.next(10);
    this.cms.fontHeadersize.next(14);
    this.cms.fontSmallsize.next(8);
  }

  normal() {
    this.cms.fontsize.next(14);
    this.cms.fontHeadersize.next(16);
    this.cms.fontSmallsize.next(10);
  }

  home() {

    window.location.reload();

    // this.cms.colorcode.next('#ffffff'); //Color
    // this.cms.colorfront.next('#164863');//backgroundcolor
    // this.cms.colorbackcode.next('linear-gradient(to right, rgb(252, 250, 250), #05074f)');//backgroundcolor);
    // this.cms.colorfrontblack.next('#174c69'); // Body Color background: linear-gradient(40deg,#2096ff,#05ffa3) !important;
  }

  // public colorcode: BehaviorSubject<string> = new BehaviorSubject<string>('#ffffff');//heading-color
  // public colorbackcode: BehaviorSubject<string> = new BehaviorSubject<string>('linear-gradient(to right, rgb(252, 250, 250), #05074f)');//backgroundcolor
  // public colorfront: BehaviorSubject<string> = new BehaviorSubject<string>('#164863');//cardheader-color
  // public colorfrontblack: BehaviorSubject<string> = new BehaviorSubject<string>('#427D9D');


  dark() {
    this.cms.colorcode.next('#ff0'); //Color
    this.cms.colorcodeblack.next('#ff0'); //Color
    this.cms.colorfront.next('#000000'); //Background Color
    this.cms.colorbackcode.next('#000000');
    this.cms.colorfrontblack.next('#000000'); // Body Color background: linear-gradient(40deg,#2096ff,#05ffa3) !important;
  }

  
  refresh() {
    window.location.reload();
  }


  // Method to confirm and switch language
  confirmLanguageSwitch(language: string) {
    const confirmationMessage = 
      language === 'Hindi' 
        ? 'क्या आप वेबसाइट की भाषा हिंदी में बदलना चाहते हैं ?' 
        : 'Do You want to change website language in English ?';

    // Show confirmation box
    if (window.confirm(confirmationMessage)) {
      this.useLanguage(language); // Switch the language if confirmed
    }

    this.setLanguage(language);
  }

  // Method to switch the language
  useLanguage(language: string) {
    console.log(`Language switched to: ${language}`);
    // Add your language switching logic here
         this.translate.use(language);
    this.langChange(language);
  }

  setLanguage(language: string) {
    if (language === 'English') {
      this.titleService.setTitle('Official Website of Rajya NITI Aayog');
    } else if (language === 'Hindi') {
      this.titleService.setTitle('राज्य नीति आयोग की आधिकारिक वेबसाइट');
    }
  }
}
