<!--Top-Header Section end-->
<div [ngStyle]="{ 'color': cms.colorfront.value , 'background-color': cms.colorbackcode.value}" class="bg-overlay img-responsive test" >
    <div class="region region-header-top panel panel-default">
        <div id="block-cmf-content-header-region-block" class="block block-cmf-content first last odd">
            <div class="wrapper common-wrapper">
                <div class="container common-container four_content">
                    <div class="common-left clearfix ">                       
                    </div>
                    <div class="common-right clearfix">
                        <ul id="header-nav">                           
                        <li class="ico-accessibility cf" >
                            <div class="input-group input-group-sm mb-3">
                                <input type="text" hidden class="form-control" placeholder="Search" aria-describedby="inputGroup-sizing-sm">
                                <script async src="https://cse.google.com/cse.js?cx=06437ecaf1c9f40c6">
                                </script>
                                <div class="gcse-search"></div>  
                            </div>          
                        </li>
                        <li class="ico-accessibility cf" >
                            <button class="btn btn-link"><a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" href="#main-content">{{"TOPNAV.SkipMainCont" | translate}} </a></button>
                            <button class="btn btn-link"><a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" href="#nav-content">{{"TOPNAV.SkipNav" | translate}} </a></button>        
                        </li>
                            <li class="ico-accessibility cf">                                
                                <button (click)="increase()" class="btn btn-link" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{"TOPNAV.TopNavA" | translate}} <sup>+</sup></button>
                                <button (click)="normal()" class="btn btn-link" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{"TOPNAV.TopNavA" | translate}} </button>
                                <button (click)="decrease()" class="btn btn-link" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{"TOPNAV.TopNavA" | translate}} <sup>-</sup></button> 
                            </li>
                            <li class="ico-accessibility cf">&nbsp;
                                <button (click)="home()" class="button_normal"></button>&nbsp;
                                <button (click)="dark()" class="button_blue"></button>&nbsp;                                                              
                            </li>
                            <!-- <li class="ico-accessibility cf">                               
                                <a title="हिन्दी पर स्विच करें" [ngStyle]="{'font-size': cms.fontsize.value +'px'}" 
                                class="langSwitch" type="button" (click)="useLanguage('Hindi')">हिंदी</a>                               
                            </li>
                            <li class="ico-accessibility cf">
                                <a title="Switch to English" [ngStyle]="{'font-size': cms.fontsize.value +'px'}" class="langSwitch" type="button"
                                    (click)="useLanguage('English')">English</a>
                            </li> -->
                            <li class="ico-accessibility cf">                               
                                <a 
                                    title="हिन्दी पर स्विच करें" 
                                    [ngStyle]="{'font-size': cms.fontsize.value + 'px'}" 
                                    class="langSwitch" 
                                    type="button" 
                                    (click)="confirmLanguageSwitch('Hindi')">हिंदी</a>                               
                            </li>
                            <li class="ico-accessibility cf">
                                <a 
                                    title="Switch to English" 
                                    [ngStyle]="{'font-size': cms.fontsize.value + 'px'}" 
                                    class="langSwitch" 
                                    type="button" 
                                    (click)="confirmLanguageSwitch('English')">English</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="wrapper header-wrapper">
        <div class="container-fluid logo_header">  
            <h1 class="logo">
                <a [routerLink]="['/home']" (click)="refresh()" routerLinkActive="router-link-active"
                    title="Chhattisgarh Emblem Logo" rel="home" class="header__logo" id="logo">
                    <img class="image image-responsive" src="assets/images/cglogo.png" style="width: 115px;"
                        alt="Chhattisgarh Emblem Logo">
                    <span *ngIf="cms.lang.value=='Hindi'" [ngStyle]="{'color': cms.colorfront.value}">राज्य नीति आयोग, छत्तीसगढ़ <div style="font-size: large;"> छत्तीसगढ़ शासन </div>   </span>
                    <span *ngIf="cms.lang.value=='English'" [ngStyle]="{'color': cms.colorfront.value}">Rajya NITI Aayog, Chhattisgarh <br> <div style="font-size: large;"> Government of Chhattisgarh </div></span>
                </a>
            </h1>
            <div class="header-right clearfix  ">
                <div class="right-content clearfix">
                    <!-- <div class="float-element" style="padding-top: 40px;">
                        <ng-container *ngIf="cms.lang.value=='Hindi'" [ngStyle]="{'color': cms.colorfront.value}">
                                <b>श्री विष्णुदेव साय</b> <br>
                                मुख्यमंत्री, छ. ग.
                        </ng-container>
                        <ng-container *ngIf="cms.lang.value=='English'" [ngStyle]="{'color': cms.colorfront.value}">
                            <b>Shri Vishnu Deo Sai</b> <br>
                                Hon'ble Chief Minister
                        </ng-container>                        
                    </div>
                    <div class="float-element white_circle">
                        <a class="header__logo "><img class="image image-responsive" title="Hon'ble Chief Minister"
                                src="../../../assets/images/portal/cm.png" style="width: 115px;"
                                alt="Hon'ble Chief Minister"></a>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
    <!--/.header-wrapper-->
 
    <section class="wrapper header-wrapper" [ngStyle]="{'background-color': cms.colorbackcode.value,'font-size': cms.fontsize.value +'px'}">
            <app-menu></app-menu>
            <app-breadcrumb></app-breadcrumb>          
            <router-outlet></router-outlet>
            <app-loader></app-loader>
            <app-footer></app-footer>       
    </section>
</div>