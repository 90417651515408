
<div class="container-fluid">
    <div class="row">            
        <div class="col-lg-12 text-center">  
            <br>
            <br>
            <p> 
                &nbsp; कृपया इस लिंक का उपयोग करके दस्तावेज़ डाउनलोड करें  : <a href="../../assets/pdf/viksitdocument.pdf" target="_blank"> 
                <span class="blink">  छत्तीसगढ़ अमृतकाल: छत्तीसगढ़ विज़न @2047, विज़न डॉक्यूमेंट   </span> 
                </a> 
            </p>
        </div>
    </div>
</div>
