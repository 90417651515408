import { FeedbackComponent } from './feedback/feedback.component';
import { ReportsComponent } from './reports/reports.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SchemedetailsComponent } from './schemedetails/schemedetails.component';
import { ImpinfoComponent } from './impinfo/impinfo.component';
import { SchemeComponent } from './scheme/scheme.component';
import { CensusComponent } from './census/census.component';
import { ProfileComponent } from './shared/profile/profile.component';
import { AuthGuard } from './guards/auth.guard';
import { AboutusComponent } from './aboutus/aboutus.component';
import { FullComponent } from './layout/full/full.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoticeboardComponent } from './noticeboard/noticeboard.component';
import { RecruitmentnoticeComponent } from './recruitmentnotice/recruitmentnotice.component';
import { AboutPortalComponent } from './about-portal/about-portal.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ScreenreaderComponent } from './screenreader/screenreader.component';
import { AcessiblitySttmntComponent } from './acessiblity-sttmnt/acessiblity-sttmnt.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { ContactusComponent } from './contactus/contactus.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TeamspcComponent } from './teamspc/teamspc.component';
import { WhoswhoComponent } from './whoswho/whoswho.component';
import { OrganizationalchartComponent } from './organizationalchart/organizationalchart.component';
import { TaskforceComponent } from './taskforce/taskforce.component';
import { OtheractivitiesComponent } from './otheractivities/otheractivities.component';
import { PlansComponent } from './plans/plans.component';
import { AchivementsComponent } from './achivements/achivements.component';
import { SdgnoticeboardComponent } from './sdgnoticeboard/sdgnoticeboard.component';
import { MounoticeboardComponent } from './mounoticeboard/mounoticeboard.component';
import { ResearchnoticeboardComponent } from './researchnoticeboard/researchnoticeboard.component';
import { TendernoticeboardComponent } from './tendernoticeboard/tendernoticeboard.component';
import { SdgComponent } from './sdg/sdg.component';
import { BestpracticeComponent } from './bestpractice/bestpractice.component';
import { ViksitcgComponent } from './viksitcg/viksitcg.component';
import { ViksitdownloadComponent } from './viksitdownload/viksitdownload.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,        
        data: {
          //title: 'Official Website SPC', // Check it
          titlehn: 'राज्य नीति आयोग की आधिकारिक वेबसाइट',
        }
      },
      {
        path: 'aboutus',
        component: AboutusComponent,
        data: {
          title: 'About Rajya Niti Aayog',
          titlehn: 'राज्य नीति आयोग के बारे में',
          urls: [
            { title: 'Home',
            titlehn: 'होम', url: '/home' },
          ]
        }
      },
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          title: 'Acts & Rules',
          titlehn: 'अधिनियम और नियम',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
          ]
        }
      },
      {
        path: 'teamspc',
        component: TeamspcComponent,
        data: {
          title: 'Executive Board',
          titlehn: 'कार्यकारी बोर्ड',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
          ]
        }      
      },
      {
        path: 'whoswho',
        component: WhoswhoComponent,
        data: {
          title: 'Administrative Directory',
          titlehn: 'प्रशासनिक निदेशिका',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
          ]
        }
      },
      {
        path: 'organizationalchart',
        component: OrganizationalchartComponent,
        data: {
          title: 'Organization Chart',
          titlehn: 'संगठनात्मक चार्ट',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
          ]
        }
      },
      {
        path: 'achivements',
        component: AchivementsComponent,
        data: {
          title: 'Achivements',
          titlehn: 'उपलब्धियाँ',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
          ]
        }
      },
      {
        path: 'sdgnoticeboard/:documentid',
        component: SdgnoticeboardComponent,
        data: {
          title: 'Related to SDGs and Projects',
          titlehn: 'एसडीजी एवं परियोजनाओ से सम्बंधित',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
          ]
        }
      },
      {
        path: 'mounoticeboard/:documentid',
        component: MounoticeboardComponent,
        data: {
          title: 'MOU and Coordination with other departments',
          titlehn: 'एमओयू एवं अन्य विभागों से समन्वय सम्बंधित ',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
          ]
        }
      },
      {
        path: 'researchnoticeboard/:documentid',
        component: ResearchnoticeboardComponent,
        data: {
          title: 'Research',
          titlehn: 'शोध अध्ययन',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
          ]
        }
      },
      {
        path: 'tendernoticeboard/:documentid',
        component: TendernoticeboardComponent,
        data: {
          title: 'Viksit Chhattisgarh @2047',
          titlehn: 'विकसित छत्तीसगढ़ @2047',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
          ]
        }
      },
      {
        path: 'scheme',
        component: SchemeComponent,
        data: {
          title: 'SDG',
          titlehn: 'SDG',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'taskforce',
        component: TaskforceComponent,
        data: {
          title: 'Task Force and Working Group',
          titlehn: 'टास्कफोर्स और कार्यदल',
          urls: [
            {
              title: 'Home',
              titlehn: 'होम', url: '/home'
            },
          ]
        }
      },      
      {
        path: 'otheractivities',
        component: OtheractivitiesComponent,
        data: {
          title: 'Other Activities',
          titlehn: 'अन्य गतिविधियाँ',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'noticeboard',
        component: NoticeboardComponent,
        data: {
          title: 'Noticeboard',
          titlehn: 'सूचना पट्ट',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'schemedetails',
        component: SchemedetailsComponent,
        data: {
          title: 'Scheme Details',
          titlehn: 'योजना का विवरण',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' },
            { title: 'Scheme', titlehn: 'योजना', url: '/scheme' },
          ]
        }
      },
      {
        path: 'rti',
        component: ImpinfoComponent,
        data: {
          title: 'RTI',
          titlehn: 'सूचना का आधिकार',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },   
      {
        path: 'recruitmentnotice',
        component: RecruitmentnoticeComponent,
        data: {
          title: 'Recruitment Notice',
          titlehn: 'भर्ती सूचना',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },         
      {
        path: 'census',
        component: CensusComponent,
        data: {
          title: 'Census',
          titlehn: 'जनगणना',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },      
      {
        path: 'reports',
        component: ReportsComponent,
        data: {
          title: 'Reports',
          titlehn: 'रिपोर्ट',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'feedback',
        component: FeedbackComponent,
        data: {
          title: 'Feedback',
          titlehn: 'प्रतिक्रिया',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'gallery',
        component: GalleryComponent,
        data: {
          title: 'Gallery',
          titlehn: 'गैलरी',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },      
      {
        path: 'copyright',
        component: AboutPortalComponent,
        data: {
          title: 'Copyright Policy',
          titlehn: 'सर्वाधिकार नीति',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'tnc',
        component: FaqsComponent,
        data: {
          title: 'Terms & Condition',
          titlehn: 'नियम और शर्तें',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'screen-read',
        component: ScreenreaderComponent,
        data: {
          title: 'Screen Reader Access',
          titlehn: 'स्क्रीन रीडर एक्सेस',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'acces-statment',
        component: AcessiblitySttmntComponent,
        data: {
          title: 'Accessibility Statement',
          titlehn: 'अभिगम्यता कथन',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'site-map',
        component: SitemapComponent,
        data: {
          title: 'Site-Map',
          titlehn: 'साइट का नक्‍शा',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'contact-us',
        component: ContactusComponent,
        data: {
          title: 'Contact Us',
          titlehn: 'हमसे संपर्क करें',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'policy',
        component: PrivacyPolicyComponent,
        data: {
          title: 'Privacy Policy & Disclaimer',
          titlehn: 'अस्वीकरण - नीतियां और खंडन',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'plans',
        component: PlansComponent,
        data: {
          title: 'Plans and Programme',
          titlehn: 'कार्यक्रम और योजनाएं',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      } ,
      {
        path: 'sdg',
        component: SdgComponent,
        data: {
          title: 'SDG Dashboard',
          titlehn: 'SDG डैशबोर्ड',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      }, 
      {
        path: 'bestpractice',
        component: BestpracticeComponent,
        data: {
          title: 'Navonmesh Portal',
          titlehn: 'नवोन्मेष पोर्टल',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      },
      {
        path: 'viksitcg',
        component: ViksitcgComponent,
        data: {
          title: 'Amritkaal: Chhattisgarh Vision @2047',
          titlehn: 'अमृतकाल : छत्तीसगढ विजन @2047',
          urls: [
            { title: 'Home', titlehn: 'होम', url: '/home' }
          ]
        }
      }     
    ]    
  }
  // ,
  // {
  //   path: 'viksitdownload',
  //   component: ViksitdownloadComponent,
  //   data: {
  //     title: 'Amritkaal: Chhattisgarh Vision @2047',
  //     titlehn: 'अमृतकाल : छत्तीसगढ विजन @2047',
  //     urls: [
  //       { title: 'Home', titlehn: 'होम', url: '/home' }
  //     ]
  //   }



  
  // }
  // {
  //   path: '',
  //   component: AuthComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     //{ path: 'admin', loadChildren: () => import('./auth/admin/admin.module').then(m => m.AdminModule) },
  //     { path: 'profile', component: ProfileComponent },
  //     { path: 'user', loadChildren: () => import('./auth/user/user.module').then(m => m.UserModule) }

  //   ]
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
